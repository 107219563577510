function ExamplePage() {
    return (
        <div className="pt-16 container mx-auto flex flex-col justify-center items-center h-screen">
            <h1 className="text-9xl">404</h1>
            <p className="text-xl">
                Halaman yang kamu minta tidak ditemukan!
            </p>
        </div>
    );
}

export default ExamplePage;
