import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./layouts/Default";

import HomePage from "./pages/HomePage";
import ExamplePage from "./pages/ExamplePage";

import Error404 from "./pages/errors/404";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<HomePage />} />
                    <Route path="example" element={<ExamplePage />} />
                    <Route path="*" element={<Error404 />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
