import { useEffect, useState } from "react";
import { motion } from "framer-motion";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import FloatingWhatsApp from "react-floating-whatsapp";

import { BsWhatsapp, BsTagsFill } from "react-icons/bs";
import { GoPackage } from "react-icons/go";
import { GiMoneyStack } from "react-icons/gi";
import { VscGraphLine } from "react-icons/vsc";
import { RiHandHeartFill } from "react-icons/ri";
import { AiOutlineMonitor } from "react-icons/ai";

import moment from "moment";
import "moment/locale/id";
moment.locale("id");

const waLink =
    "https://api.whatsapp.com/send/?phone=6282170214495&text=" +
    encodeURI(
        "Assalamualaikum, Saya melihat informasi aplikasi kasir dari website aplikasikasirpadang.com, saya mau bertanya mengenai ..."
    );

const daftarHarga = [
    {
        imageUrl: "/assets/images/pcdiata.png",
        title: "Personal Computer (PC)",
        harga: 3700000,
        destinationUrl: waLink,
        specs: [
            "Monitor LCD 19 inc",
            "Windows 7",
            "Intel Core i3",
            "Memory 2 GB",
            "HDD 500 GB Seagate",
            "Casing",
            "Keyboard & Mouse",
        ],
    },
    {
        imageUrl: "/assets/images/printer.jpg",
        title: "Printer Kasir Qpos 58",
        harga: 700000,
        destinationUrl: waLink,
        specs: [
            "Dot Matrix, Manual Cutter 58 Mm",
            "Usb Interface",
            "Support Cash Drawer",
            "Print Method: 9-pin Serial Impact Dot Matrix",
            "Print Speed: 4.7 lines Per Second Paper Width: 76.0 ± 0.5 mm",
            "Ink Ribbon: ERC 38",
            "Power: AC Adaptor 24 V",
        ],
    },
    {
        imageUrl: "/assets/images/drawer.jpg",
        title: "Cash Drawer iWare",
        harga: 800000,
        destinationUrl: waLink,
        specs: [
            "3 Lock Position",
            "4 Bills, 8 Coins, Full 100% Metal Casing",
            "RJ 11 Interface, can be Directly to Computer via USB Trigger",
        ],
    },
    {
        imageUrl: "/assets/images/scanner.webp",
        title: "Barcode Scanner IWARE BS-W750S",
        harga: 500000,
        destinationUrl: waLink,
        specs: [
            "USB Keyboard Interface",
            "Scan Speed: 450 scan/sec",
            "Manual & Automatic Scanning",
            "Weight: 176 g",
            "Jarak Deteksi hingga 20 cm",
        ],
    },
    {
        imageUrl: "/assets/images/2.png",
        title: "Aplikasi Kasir Mediatama",
        harga: 2500000,
        destinationUrl: waLink,
        specs: [
            "Pembelian & penjualan barang",
            "Kontrol persediaan barang",
            "Data supplier",
            "Fitur modal perhari kasir",
            "Pencarian barang",
            "Return barang",
            "Pengelolaan stock opname",
            "Laporan penjualan",
            "Laporan pembelian",
            "Laporan kas keuangan",
            "Laporan penjualan berdasarkan barang",
            "Laporan penjualan berdasarkan karyawan",
            "Laporan penjualan berdasarkan kategori",
            "Laporan return barang",
            "Peringatan ketika stok menipis",
            "Laporan barang kategori fast moving penjualan > 100 pcs",
            "Laporan barang kategori middle dengan penjualan > 100 - 10 pcs",
            "Laporan barang slow moving dengan penjualan 0 - 10 pcs",
            "Training dan pelatihan admin",
            "Full maintenance dan perawatan",
            "Layanan konsultasi admin",
        ],
    },
];

const kelebihan = [
    {
        title: "Pantau & Kontrol Penjualan Cabang Manapun",
        describe:
            "Anda dapat memantau dan mengontrol Penjualan cabang manapun menggunakan aplikasi",
        icon: <AiOutlineMonitor />,
    },
    {
        title: "Kelola Stok",
        describe:
            "Dengan sistem kasir anda dapat mengelola stok. Pengelolaan stok yang rapi bisa memberikan pemahaman yang lebih dalam akan kebutuhan bisnis anda.",
        icon: <GoPackage />,
    },
    {
        title: "Kontrol Biaya Usaha",
        describe:
            "Anda dapat mengontrol biaya usaha anda seperti kas masuk dan kas keluar toko",
        icon: <GiMoneyStack />,
    },
    {
        title: "Laporan Keuangan Lengkap",
        describe:
            "Anda dapat mengakses seluruh laporan penjualan secara real-time dari smartphone, table, maupun komputer kapanpun dan dimanapun",
        icon: <VscGraphLine />,
    },
    {
        title: "Tingkatkan Loyalitas Pelanggan",
        describe:
            "Dengan menggunakan aplikasi usaha anda menjadi lebih profesional dan dipercaya oleh pelanggan",
        icon: <RiHandHeartFill />,
    },
];

const jenis_usaha = [
    {
        title: "Food & Beverage",
        thumbnail: "/assets/images/foodnbeverage.webp",
    },
    {
        title: "Online Shop",
        thumbnail: "/assets/images/onlineshop.jpg",
    },
    {
        title: "Fashion",
        thumbnail: "/assets/images/fashion.jpg",
    },
    {
        title: "Toko Retail",
        thumbnail: "/assets/images/tokoretail.jpg",
    },
    {
        title: "Barbershop",
        thumbnail: "/assets/images/barbershop.jpg",
    },
    {
        title: "Laundry",
        thumbnail: "/assets/images/laundry.webp",
    },
    {
        title: "Autocare",
        thumbnail: "/assets/images/autocare.jpg",
    },
    {
        title: "Layanan Profesional",
        thumbnail: "/assets/images/profesional.jpg",
    },
];

const gallery = [
    "/assets/images/gallery/1.jpeg",
    "/assets/images/gallery/2.jpeg",
    "/assets/images/gallery/3.jpeg",
    "/assets/images/gallery/4.jpeg",
    "/assets/images/gallery/5.png",
    "/assets/images/gallery/6.png",
    "/assets/images/gallery/7.png",
];

const Hiasan = () => (
    <>
        <div className="md:block hidden bg-secondary w-96 h-96 rounded-full absolute left-0 top-96 -translate-y-1/2 -translate-x-1/2"></div>
        <div
            className="md:block hidden absolute left-0 w-56 h-96 top-96 -translate-y-1/2"
            style={{
                backgroundImage: "url(/assets/images/pattern.svg)",
            }}
        ></div>
        <div className="md:block hidden bg-secondary w-96 h-96 rounded-full absolute right-0 bottom-2 translate-y-1/2 translate-x-1/2"></div>
        <div
            className="md:block hidden absolute right-0 w-56 h-96 bottom-2 translate-y-1/2"
            style={{
                backgroundImage: "url(/assets/images/pattern.svg)",
            }}
        ></div>
    </>
);

function HomePage() {
    const [isShow, setIsShow] = useState(false);
    const [countdown, setCountdown] = useState("...");

    useEffect(() => {
        let sundayDate = 7 - moment().add(7).format("d");
        let currentDate = parseInt(moment().add(7).format("D"));

        // "2022-09-"
        setCountdown(moment(moment().format("Y-M-") + (sundayDate + currentDate)).fromNow());
    }, []);

    return (
        <>
            <FloatingWhatsApp
                phoneNumber="6282170214495"
                accountName="Ferri Ahmad Effindri"
                avatar="/assets/images/profilewa.jpg"
                chatMessage="Halo! Ada yang bisa kami bantu?"
                statusMessage="Biasanya membalas dalam 1 jam"
                className="custom1"
            />
            <div
                className="min-h-screen flex justify-center flex-col bg-no-repeat bg-bottom relative"
                style={{
                    backgroundImage: `url(/assets/images/wave-bottom.svg)`,
                }}
            >
                <div
                    className="absolute top-0 left-0 w-full h-full -z-20 bg-fixed bg-cover"
                    style={{
                        backgroundImage: `url(/assets/images/tokoretail.jpg)`,
                    }}
                ></div>
                <div className="absolute top-0 left-0 w-full h-full -z-10 bg-black opacity-80"></div>
                <div className="container mx-auto px-10 lg:flex flex-row-reverse justify-between w-full py-20">
                    <div className="flex flex-col justify-center">
                        <motion.img
                            initial={{
                                opacity: 0,
                                x: 50,
                            }}
                            whileInView={{
                                opacity: 1,
                                x: 0,
                            }}
                            transition={{
                                delay: 0.1,
                            }}
                            src="/assets/images/diata-1.png"
                            alt="Diata Logo"
                            className="w-[200px] md:w-[400px] lg:w-[600px]"
                        />
                    </div>
                    <div className="flex flex-col justify-end">
                        <motion.h3
                            initial={{
                                opacity: 0,
                                x: -50,
                            }}
                            whileInView={{
                                opacity: 1,
                                x: 0,
                            }}
                            transition={{
                                delay: 0.1,
                            }}
                            className="text-2xl md:text-4xl lg:text-6xl font-semibold mt-10 text-white"
                        >
                            Aplikasi Kasir Diata
                        </motion.h3>
                        <motion.h4
                            initial={{
                                opacity: 0,
                                x: -50,
                            }}
                            whileInView={{
                                opacity: 1,
                                x: 0,
                            }}
                            transition={{
                                delay: 0.3,
                            }}
                            className="text-xl md:text-2xl md:my-1 text-gray-400"
                        >
                            Cara mudah mengatur bisnis anda!
                        </motion.h4>
                        <motion.p
                            initial={{
                                opacity: 0,
                                x: -50,
                            }}
                            whileInView={{
                                opacity: 1,
                                x: 0,
                            }}
                            transition={{
                                delay: 0.6,
                            }}
                            className="my-2 md:text-xl md:w-2/3 font-light text-gray-400"
                        >
                            Aplikasi Kasir Diata merupakan aplikasinya orang
                            Padang yang memudahkan anda dalam mengatur
                            Penjualan, Manajemen, dan Pembukuan Keuangan bisnis
                            Anda
                        </motion.p>
                        <motion.div
                            initial={{
                                opacity: 0,
                                y: 50,
                            }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{
                                delay: 1.2,
                            }}
                            className="my-5"
                        >
                            <a
                                href={waLink}
                                target="_blank"
                                rel="noreferrer"
                                className="bg-green-600 text-white p-3 px-5 rounded-lg flex items-center gap-2 w-max hover:opacity-90"
                            >
                                <BsWhatsapp className="text-xl" /> Pesan
                                Sekarang
                            </a>
                        </motion.div>
                    </div>
                </div>
            </div>
            <div className="text-white bg-primary relative overflow-x-clip">
                <Hiasan />
                <div className="container mx-auto px-10 md:px-20 py-20 md:pt-0">
                    <motion.h1
                        initial={{
                            opacity: 0,
                            y: 50,
                        }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{
                            delay: 0,
                        }}
                        className="text-2xl sm:text-3xl md:text-5xl text-center mb-20"
                    >
                        Kelola bisnis dengan aplikasi yang tepat
                    </motion.h1>
                    <div className="relative">
                        <div className="xl:flex flex-row-reverse">
                            <div className="xl:sticky top-[30vh] h-36 md:pb-96 mb-10 xl:pl-20">
                                <motion.img
                                    initial={{
                                        opacity: 0,
                                        y: 50,
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0,
                                    }}
                                    transition={{
                                        delay: 0,
                                    }}
                                    src="/assets/images/komputer-kasir.png"
                                    alt="Komputer Kasir"
                                    className="w-[600px] mx-auto"
                                />
                            </div>
                            <div className="flex-1 mt-44 sm:mt-80 md:mt-52 xl:mt-0 flex flex-col md:flex-row xl:flex-col flex-wrap gap-10">
                                {kelebihan.map((item, no) => (
                                    <motion.div
                                        initial={{
                                            opacity: 0,
                                            x: -50,
                                        }}
                                        whileInView={{
                                            opacity: 1,
                                            x: 0,
                                        }}
                                        transition={{
                                            delay: 0.2 * no,
                                        }}
                                        key={item.title}
                                        className="flex flex-col xl:flex-row md:w-1/3 xl:w-full flex-grow gap-5 xl:gap-10 bg-gradient-to-r from-primary to-transparent"
                                    >
                                        <div className="text-7xl">
                                            {item.icon}
                                        </div>
                                        <div>
                                            <h3 className="text-2xl text-secondary font-bold">
                                                {item.title}
                                            </h3>
                                            <p className="text-xl">
                                                {item.describe}
                                            </p>
                                        </div>
                                    </motion.div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="bg-gradient-to-b from-white to-blue-200 py-20 bg-no-repeat bg-cover bg-top"
                style={{ backgroundImage: `url(/assets/images/wave-top.svg)` }}
            >
                <motion.div
                    initial={{
                        opacity: 0,
                        y: 50,
                    }}
                    whileInView={{
                        opacity: 1,
                        y: 0,
                    }}
                    transition={{
                        delay: 0,
                    }}
                    className="mb-12 mt-28"
                >
                    <h1 className="text-3xl md:text-5xl text-center md:font-semibold mb-4">
                        Apapun bisnismu <span className="font-bold">Diata</span>{" "}
                        solusinya
                    </h1>
                    <p className="text-center md:text-xl">
                        Dukungan untuk segala jenis usaha di Indonesia
                    </p>
                </motion.div>
                <div className="container mx-auto lg:px-20 px-5">
                    <div className="flex flex-row flex-wrap md:gap-5 gap-2">
                        {jenis_usaha.map((item, no) => (
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    y: 50,
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0,
                                }}
                                transition={{
                                    delay: 0.1 * no,
                                }}
                                key={item.title}
                                style={{
                                    backgroundImage: `url(${item.thumbnail})`,
                                }}
                                className="shadow-lg w-1/3 lg:w-1/5 flex-grow flex-wrap relative pb-[50%] lg:pb-[15%] bg-cover"
                            >
                                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-30" />
                                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-semibold text-center text-white drop-shadow text-2xl">
                                    {item.title}
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="text-white bg-primary py-20 relative overflow-x-clip lg:mt-20">
                <Hiasan />
                <div className="container mx-auto">
                    <motion.h1
                        initial={{
                            opacity: 0,
                            y: -50,
                        }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{
                            delay: 0,
                        }}
                        className="text-3xl md:text-5xl text-center font-semibold mb-4"
                    >
                        Harga perangkat mesin kasir online
                    </motion.h1>
                    <div className="mt-20">
                        <Carousel
                            sliderClass="flex m-20 text-black"
                            itemClass="w-1/4 p-5 flex flex-col"
                            additionalTransfrom={0}
                            minimumTouchDrag={80}
                            autoPlaySpeed={3000}
                            slidesToSlide={1}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            focusOnSelect={false}
                            autoPlay={false}
                            arrows={true}
                            responsive={{
                                desktop: {
                                    breakpoint: { max: 3000, min: 1024 },
                                    items: 4,
                                },
                                tablet: {
                                    breakpoint: { max: 1024, min: 464 },
                                    items: 2,
                                },
                                mobile: {
                                    breakpoint: { max: 464, min: 0 },
                                    items: 1,
                                },
                            }}
                            draggable
                            infinite
                            swipeable
                        >
                            {daftarHarga.map((item, x) => (
                                <>
                                    <div
                                        key={x}
                                        className="flex-1 bg-white p-5 rounded-t-lg shadow-lg"
                                    >
                                        <div className="h-40 flex flex-col justify-center">
                                            <img
                                                src={item.imageUrl}
                                                alt={`${item.title}'s images`}
                                                className="mx-auto my-5 max-h-40"
                                            />
                                        </div>
                                        <h3 className="text-xl mt-5 font-semibold text-primary text-center mb-3">
                                            {item.title}
                                        </h3>
                                        <h4 className="text-center text-2xl mb-5">
                                            {Intl.NumberFormat("id-ID", {
                                                style: "currency",
                                                currency: "IDR",
                                            }).format(item.harga)}
                                        </h4>
                                        <ul className="px-7">
                                            {item.specs.map(
                                                (spec, y) =>
                                                    y < 10 && (
                                                        <li
                                                            key={y}
                                                            className="list-[circle]"
                                                        >
                                                            {spec}
                                                        </li>
                                                    )
                                            )}
                                        </ul>
                                        {item.specs.length > 10 && (
                                            <>
                                                <ul
                                                    className={`px-7 ${
                                                        isShow
                                                            ? "block"
                                                            : "hidden"
                                                    }`}
                                                >
                                                    {item.specs.map(
                                                        (spec, y) =>
                                                            y >= 10 && (
                                                                <li
                                                                    key={y}
                                                                    className="list-[circle]"
                                                                >
                                                                    {spec}
                                                                </li>
                                                            )
                                                    )}
                                                </ul>
                                                <div
                                                    className={`text-center text-sm text-gray-500 mt-3 cursor-pointer`}
                                                    onClick={() =>
                                                        setIsShow(!isShow)
                                                    }
                                                >
                                                    {isShow
                                                        ? "Lebih sedikit"
                                                        : "Lihat selengkapnya"}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <a
                                        href={item.destinationUrl}
                                        className="bg-green-600 shadow-lg text-white cursor-pointer p-3 px-5 rounded-b-lg flex items-center gap-2 hover:opacity-90"
                                    >
                                        <BsWhatsapp className="text-xl" /> Pesan
                                        Sekarang
                                    </a>
                                </>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
            <div
                className="bg-gradient-to-b from-white to-blue-200 py-20 pb-12 bg-no-repeat bg-cover bg-top"
                style={{ backgroundImage: `url(/assets/images/wave-top.svg)` }}
            >
                <div className="container mx-auto py-16">
                    <h1 className="text-4xl font-semibold text-center mb-10">
                        Upgrade level bisnismu sekarang
                    </h1>
                    <div className="flex md:flex-row flex-col justify-center items-center gap-16 md:h-96">
                        <img
                            src="/assets/images/kasir-lengkap.png"
                            alt="Paket lengkap"
                            className="w-96"
                        />
                        <div className="px-10">
                            <p className="text-3xl mb-2">
                                Paket lengkap untuk memulai usahamu
                            </p>
                            <div>
                                <h1 className="text-5xl mb-1 font-semibold">
                                    {Intl.NumberFormat("id-ID", {
                                        style: "currency",
                                        currency: "IDR",
                                    }).format(
                                        Math.ceil(
                                            daftarHarga.reduce(
                                                (a, b) => a + (b.harga || 0),
                                                0
                                            ) * 0.9146341463414634
                                        )
                                    )}
                                </h1>
                                <div className="flex items-center mb-10 md:flex-col lg:flex-row">
                                    <h2 className="text-3xl font-semibold line-through">
                                        {Intl.NumberFormat("id-ID", {
                                            style: "currency",
                                            currency: "IDR",
                                        }).format(
                                            daftarHarga.reduce(
                                                (a, b) => a + (b.harga || 0),
                                                0
                                            )
                                        )}
                                    </h2>
                                    <div>
                                        <div className="bg-secondary text-black p-1 px-2 ml-5 text-sm font-semibold flex items-center">
                                            <BsTagsFill className="mr-1" />
                                            Harga promo berakhir{" "}
                                            {countdown.toLowerCase()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a
                                href={waLink}
                                target="_blank"
                                rel="noreferrer"
                                className="bg-green-600 text-white p-3 px-5 rounded-lg flex items-center gap-2 w-max hover:opacity-90"
                            >
                                <BsWhatsapp className="text-xl" /> Pesan
                                Sekarang
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-primary text-white">
                <div className="container mx-auto py-10">
                    <div className="flex justify-center items-center gap-10">
                        <div>
                            <a
                                href="https://www.instagram.com/diatapos/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src="/assets/images/ig.png"
                                    alt="Instagram"
                                    className="w-20"
                                />
                            </a>
                        </div>
                        <div>
                            <h2 className="text-2xl">Temukan kami di</h2>
                            <a
                                href="https://www.instagram.com/kasirdiata/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <h3 className="text-5xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 hover:from-yellow-500 via-red-500 to-yellow-500 hover:to-pink-500 w-max">
                                    @diatapos
                                </h3>
                            </a>
                        </div>
                    </div>
                    <div className="mt-10">
                        <Carousel
                            sliderClass="flex m-20 text-black"
                            itemClass="w-1/4 p-2 flex flex-col"
                            additionalTransfrom={0}
                            minimumTouchDrag={80}
                            autoPlaySpeed={3000}
                            slidesToSlide={1}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            focusOnSelect={false}
                            autoPlay={true}
                            arrows={true}
                            responsive={{
                                desktop: {
                                    breakpoint: { max: 3000, min: 1024 },
                                    items: 4,
                                },
                                tablet: {
                                    breakpoint: { max: 1024, min: 464 },
                                    items: 2,
                                },
                                mobile: {
                                    breakpoint: { max: 464, min: 0 },
                                    items: 1,
                                },
                            }}
                            draggable
                            infinite
                            swipeable
                        >
                            {gallery.map((item, x) => (
                                <div
                                    key={x}
                                    className="pb-[100%] bg-cover border rounded-lg shadow-sm"
                                    style={{ backgroundImage: `url(${item})` }}
                                ></div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
            <div className="container mx-auto">
                <footer>
                    <div className="p-5 md:flex justify-between items-center">
                        <img
                            src="/assets/images/diata-1.png"
                            alt="Logo Diata"
                            className="w-32"
                        />
                        <div className="text-gray-400">
                            CV. Mediatama Web Indonesia | Copyright 2022 Alright
                            reserved
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default HomePage;
