import { Outlet, Link, useLocation } from "react-router-dom";
import { BsWhatsapp } from "react-icons/bs";
import { useEffect, useState } from "react";

const ACTIVE =
    "block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0";
const DEACTIVE =
    "block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0";

const waLink =
    "https://api.whatsapp.com/send/?phone=6282170214495&text=" +
    encodeURI(
        "Assalamualaikum, Saya melihat informasi aplikasi kasir dari website aplikasikasirpadang.com, saya mau bertanya mengenai ..."
    );

const Layout = () => {
    const [header, setHeader] = useState(false);

    const listenScrollEvent = (event) => {
        if (window.scrollY < 73) {
            return setHeader(false);
        } else if (window.scrollY > 70) {
            return setHeader(true);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);
        return () => window.removeEventListener("scroll", listenScrollEvent);
    }, []);

    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/")[1];

    return (
        <>
            <nav
                className={`${
                    header ? "bg-white border-gray-200 shadow-lg" : "bg-transparent py-8"
                } transition-all px-5 sm:px-4 py-2.5 fixed w-full z-50`}
            >
                <div className="container flex flex-wrap justify-between items-center mx-auto">
                    <a href="/" className="flex items-center">
                        <img
                            src="/assets/images/diata-1.png"
                            className="mr-3 h-6 sm:h-9"
                            alt="Diata Logo"
                        />
                    </a>
                    <button
                        data-collapse-toggle="navbar-default"
                        type="button"
                        className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                        aria-controls="navbar-default"
                        aria-expanded="false"
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </button>
                    <div
                        className="hidden w-full md:block md:w-auto"
                        id="navbar-default"
                    >
                        <ul className="flex flex-col items-center mt-4 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0">
                            {/* <li>
                                <Link
                                    className={
                                        splitLocation === "" ? ACTIVE : DEACTIVE
                                    }
                                    to="/"
                                >
                                    Home
                                </Link>
                            </li> */}
                            {/* <li>
                                <Link
                                    className={(splitLocation === "example") ? ACTIVE : DEACTIVE}
                                    to="/example"
                                >
                                    ExamplePage
                                </Link>
                            </li> */}
                            <li>
                                <a
                                    href={waLink}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="bg-green-600 text-white p-3 px-5 rounded-lg flex items-center gap-2 w-max hover:opacity-90"
                                >
                                    <BsWhatsapp className="text-xl" /> Pesan
                                    Sekarang
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="overflow-x-clip">
                <Outlet />
            </div>
        </>
    );
};

export default Layout;
